.hidden-node{
    display: none;
}

.node-item{
    margin-left: 20px;
}

.first-node.node-item{
    margin-left: 0px;
}

.node-item-label, .node-item-label-hoverable{
    cursor: pointer;
    transition: all 0.2s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none;
    user-select: none;
}

.node-item-label-hoverable:hover{
    background-color: #EBEFF2;
    transition: all 0.2s;
}

.node-item-label .label-icons{
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-right: 5px;
}

.default-node{
    display: flex;
    align-items: center;
}

.node-item-label .label-text{

}



.chevron::before {
	border-style: solid;
	border-width: 0.25em 0.25em 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	left: 0.15em;
	position: relative;
	top: 0.15em;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 0.45em;
}

.chevron.right:before {
	left: 0;
	transform: rotate(45deg);
}

.chevron.bottom:before {
	top: 0;
	transform: rotate(135deg);
}
